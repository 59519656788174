import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Seo from "../components/seo"
import Layout from "../components/layout"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const pageTitle = "ژنراتورهای دیزل عرضه شده توسط شرکت مشارکت نیرو"

export default function DieselGeneratorIndex(props) {
  const { data } = props
  const DGlist = data.allContentfulDieselGenerator.nodes

  // List of all DGs satisfing all the filters
  const [filteredList, setFilteredList] = useState(DGlist)

  // Selected Brand name filter
  const [selectedBrand, setSelectedBrand] = useState("")

  // Selected Prime Power filter
  const [selectedPP, setSelectedPP] = useState("")

  // Selected Model filter
  const [selectedModel, setSelectedModel] = useState("")

  // Update seletedBrand state
  const handleBrandChange = event => {
    setSelectedBrand(event.target.value)
  }

  const handleInputPPChange = event => {
    setSelectedPP(event.target.value)
  }

  const handleInputModelChange = event => {
    setSelectedModel(event.target.value)
  }

  // Use React Hooks to trigger the an update to filtered Values
  useEffect(() => {
    const filterByBrand = filteredData => {
      // Avoid filter for empty string
      if (!selectedBrand) {
        return filteredData
      }

      const filteredDGs = filteredData.filter(
        dg => dg.brand.title.split(" ").indexOf(selectedBrand) !== -1
      )
      return filteredDGs
    }

    const filterByPP = filteredData => {
      if (!selectedPP) {
        return filteredData
      }

      const filteredDGs = filteredData.filter(
        dg =>
          dg.primePowerKva
            .toString()
            .toLowerCase()
            .indexOf(selectedPP.toLowerCase()) !== -1
      )
      return filteredDGs
    }

    const filterByModel = filteredData => {
      if (!selectedModel) {
        return filteredData
      }

      const filteredDGs = filteredData.filter(
        dg => dg.model.toLowerCase().indexOf(selectedModel.toLowerCase()) !== -1
      )
      return filteredDGs
    }

    var filteredData = filterByBrand(DGlist)
    var filteredData = filterByModel(filterByPP(filteredData))

    setFilteredList(filteredData)
  }, [selectedBrand, selectedPP, selectedModel])

  return (
    <Layout>
      <Seo
        title={pageTitle}
        description="ژنراتورهای دیزلی ارائه شده توسط شرکت مشارکت نیرو آریا کیش"
      />
      <section
        id="diesel-generators-list"
        className=" grid grid-col-1 bg-primary text-white text-4xl font-medium px-10 py-20 text-center sm:px-1 sm:py-5"
      >
        <h1 className="text-3xl sm:text-xl">{pageTitle}</h1>
        <h2 className="text-2xl sm:text-lg">
          همراه با گارانتی و خدمات پس از فروش
        </h2>

        <div
          id="filters"
          className="grid grid-cols-3 sm:grid-cols-1 sm:space-y-5 sm:justify-items-start sm:px-2 text-xl text-primary py-5 border rounded-md shadow-md shadow-orange-400"
        >
          <div id="brand-filter" className="">
            <label className="text-white">برند : </label>
            <select
              id="brand-input"
              value={selectedBrand}
              onChange={handleBrandChange}
            >
              <option value="">همه برندها</option>
              <option value="Cummins">Cummins</option>
              <option value="Volvo">Volvo</option>
              <option value="Baudouin">Baudouin</option>
            </select>
          </div>
          <div id="model-filter" className="text-center">
            <label className="text-white">مدل : </label>
            <input
              type="text"
              aria-aria-label="search"
              placeholder=" لطفا مدل ژنراتوررا تایپ کنید"
              onChange={handleInputModelChange}
            />
          </div>
          <div id="power-filter" className="text-center">
            <label className="text-white">پرایم پاور : </label>
            <input
              type="text"
              aria-aria-label="search"
              placeholder=" لطفا پرایم پاور را تایپ کنید"
              onChange={handleInputPPChange}
            />
          </div>
        </div>

        <div
          id="DGs-list"
          className="grid grid-cols-3 gap-10 px-10 py-12 sm:grid-cols-1 sm:px-0 sm:py-5 sm:gap-8 md:grid-cols-1 md:px-0 md:py-5 md:gap-8  lg:px-0 lg:py-5 lg:gap-8 xl:grid-cols-2 xl:px-0 xl:py-5 xl:gap-8"
        >
          {filteredList.map((item, index) => {
            const cardImage = getImage(item.cardImage)
            return (
              <div
                id="DG-item"
                key={index}
                className="border rounded-md px-2 py-2 shadow-lg shadow-gray-300"
              >
                <GatsbyImage
                  image={cardImage}
                  alt={item.model}
                  className="px-1 py-1 shadow-md shadow-orange-400"
                />
                <h3 className="text-xl py-2">
                  دیزل ژنراتور &nbsp;{item.brand.title}
                </h3>
                <h4 className="text-2xl py-2 font-semibold">
                  مدل&nbsp;
                  <b className="text-secondary">{item.model}</b>
                </h4>

                <div className="shadow-sm overflow-hidden py-2 color-white px-5">
                  <table className="text-2xl border rounded-xl table-auto w-full font-normal ">
                    <thead>
                      <tr>
                        <th className="bg-slate-200 text-primary text-right px-5 py-5">
                          مشخصات
                        </th>
                        <th className="bg-slate-200 text-primary  text-right px-5 py-5">
                          مقدار
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="border border-xl">
                        <td className="text-right px-5 py-2">
                          توان استندبای KVA
                        </td>
                        <td className="text-right px-5 py-2">
                          {item.standbyPowerKva}
                        </td>
                      </tr>
                      <tr className="border border-xl">
                        <td className="text-right px-5 py-2">
                          توان استندبای KW
                        </td>
                        <td className="text-right px-5 py-2">
                          {item.standbyPowerKwe}
                        </td>
                      </tr>
                      <tr className="border border-xl">
                        <td className="text-right px-5 py-2">توان پرایم KVA</td>
                        <td className="text-right px-5 py-2">
                          {item.primePowerKva}
                        </td>
                      </tr>
                      <tr className="border border-xl">
                        <td className="text-right px-5 py-2">توان پرايم KW</td>
                        <td className="text-right px-5 py-2">
                          {item.primePowerKwe}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <button className="bg-secondary text-white text-xl font-semibold px-12 py-2 mt-10 mb-5 rounded max-w-lg shadow-lg hover:bg-slate-200 hover:text-black hover:shadow-secondary">
                  <Link
                    to={`/diesel-generator/${item.model}`}
                    activeClassName="active"
                  >
                    اطلاعات بیشتر
                  </Link>
                </button>
              </div>
            )
          })}
        </div>
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query DieselGeneratorIndexQuery {
    allContentfulDieselGenerator(
      sort: { fields: [brand___title, primePowerKva], order: ASC }
    ) {
      nodes {
        model
        brand {
          title
        }
        standbyPowerKva
        standbyPowerKwe
        primePowerKva
        primePowerKwe
        catalog {
          title
          file {
            url
          }
        }
        cardImage {
          title
          gatsbyImageData(width: 320)
        }
      }
    }
  }
`
